$primary-color: #008AC8;

$h1-weight: 700;
$h1-size: 24px;
$h1-line-height: 36px;

$h2-weight: 600;
$h2-size: 20px;
$h2-line-height: 30px;

$h4-weight: 600;
$h4-size: 16px;
$h4-line-height: 24px;

$p-weight: 400;
$p-size: 14px;
$p-line-height: 21px;

$color-white: #fff;