@import 'variables';

h1{
    font-weight: $h1-weight;
    font-size: $h1-size;
    line-height: $h1-line-height;
}

h2{
    font-weight: $h2-weight;
    font-size: $h2-size;
    line-height: $h2-line-height;
}

h4{
    font-weight: $h4-weight;
    font-size: $h4-size;
    line-height: $h4-line-height;
}

p{
    font-weight: $p-weight;
    font-size: $p-size;
    line-height: $p-line-height;
}

.text-center{
    text-align: center;
}

.v-height{
    height: 100vh;
}

.color-white{
    color: $color-white;
}

.color-primary{
    color: $primary-color;
}

.bg-primary{
    background-color: $primary-color;
}

.relative{
    position: relative;
}

.absolute{
    position: absolute;
}

.mb-0{
    margin-bottom: 0;
}

.report-card {
    font-size: 38px;;
    display: flex;
    justify-content: space-evenly;
    align-content: center;
    flex-direction: row;
    align-items: center;
}

.report-wrapper {        
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 50px;
}

.report-wrapper > div {
    margin: 5px 5px 5px 0px;
}

.ant-table {
    min-height: 560px;
  }